import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function stripExtension(filename) {
  // Remove file extension
  let nameWithoutExt = filename.replace(/\.[^/.]+$/, "");
  // Remove _trim if it's at the end of the filename
  return nameWithoutExt.replace(/_trim$/i, "");
}

const API_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API_URL;

const normalizeText = (text) => text.normalize("NFC");

const Collapse = (props) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [displayTitle, setDisplayTitle] = useState("");
  const navigate = useNavigate();

  const handleClick = (title) => {
    props.setExercise({
      ...props.exercise,
      videoPath: `${props.path}/${title}`,
    });
    navigate(-1);
  };

  useEffect(() => {
    const fetchDisplayTitle = async () => {
      try {
        const response = await fetch("/titles.csv");
        const text = normalizeText(await response.text());
        const rows = text.split("\n").map((row) => row.split(","));

        const searchPath = normalizeText(`${props.path}/${stripExtension(props.title)}`);

        const matchingRow = rows.find((row) => row[0] === searchPath);
        if (matchingRow && matchingRow[1]) {
          setDisplayTitle(matchingRow[1].trim());
        } else {
          setDisplayTitle(searchPath);
          console.log('File not found')
        }
      } catch (error) {
        console.error("Error reading CSV:", error);
        setDisplayTitle(`${props.path}/${stripExtension(props.title)}`);
      }
    };

    fetchDisplayTitle();
  }, [props.path, props.title]);

  useEffect(() => {
    if (open && url === "") {
      const fetchUrl = async () => {
        try {
          const response = await axios.get(`${API_URL}/bucket/presigned-url-file`, {
            headers: {
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            },
            params: {
              file: encodeURIComponent(`${props.path.slice(1)}/${props.title}`),
            },
          });
          setUrl(response.data);
        } catch (err) {
          console.error("Error fetching presigned URL:", err);
        }
      };

      fetchUrl();
    }
  }, [open, url, props.path, props.title]);

  return (
    <div className="border border-sky-800 rounded-md px-5 py-2 my-4">
      <h3 
        className="text-lg font-semibold cursor-pointer hover:text-sky-600 flex justify-between items-center"
        onClick={() => setOpen(!open)}
      >
        <span>{displayTitle}</span>
        {props.state && (
          <button
            className="inline-block w-7 bg-sky-600 text-white font-medium text-2xl leading-tight rounded shadow-md hover:bg-sky-700"
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering the h3's onClick
              handleClick(stripExtension(props.title));
            }}
          >
            +
          </button>
        )}
      </h3>
      {open && (
        <>
          <div className="mt-2 text-sm text-gray-600">
            Utförlig beskrivning saknas.
          </div>
          {url && (
            <div className="flex justify-center py-2">
              <video controls key={props.title}>
                <source src={url} />
              </video>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Collapse;